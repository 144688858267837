.container{
    width: 100%;
    max-width: 130rem;
    padding: 0rem 4rem;
    display: flex;
    gap: 2rem;
}

.dashboard{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.orders{
    width: 30%;
}

.dashboardHead{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.dashboardHead>.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head>:nth-child(1){
    font-size: 1.5rem;
    font-family: 500;
}

.dashboardHead > select{
    border: none;
    background-color: var(--orange-gradient);
    padding: 10px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 0.8rem;
} 

.cards{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}
.cardHead>:nth-child(1){
    color:aliceblue;
}
.cardHead >:nth-child(2){
    margin-left: 1rem;
    font-size: 0.8rem;
    color: var(--green);
}

.cardAmount > :nth-child(1) {
    font-size: 0.9rem;
    color: aliceblue;
    position: relative;
}
.cardAmount >:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 8px;
}

